import React, { Fragment } from "react";
import Image from "next/image";
import features4 from "../../assets/images/features-4.png";
import features5 from "../../assets/images/features-5.png";
import features6 from "../../assets/images/features-6.png";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper";
import styles from "@/styles/shopbybudget.module.scss";
import { homeLinks } from "@/data/home";
import Link from "next/link";
SwiperCore.use([Navigation, Pagination]);

const ShopByBudget = () => {
  return (
    <Fragment>
      <section className={styles.shopbybudget}>
        <div className="container">
          <div className={styles.shopbybudgetheader}>
            <h2 className={styles.shopbybudgetheader_text}>
              {homeLinks.shopByBudget.title}
            </h2>
            {/* <p>{homeLinks.shopByBudget.subTitle}</p> */}
          </div>

          <div className="row portfolio-container">
            {homeLinks.shopByBudget.images.map((v: any, i) => (
              <div
                className="col-lg-3 col-md-3 portfolio-item filter-app"
                key={i}
              >
                <Link href={v.link} passHref>
                  <div className="portfolio-wrap text-center">
                    <Image
                      src={v.image}
                      className="img-fluid"
                      alt="Shop by Budget "
                      height={356}
                      width={335}
                    />
                    <div className={styles.h5}>{v.price}</div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default ShopByBudget;
